import { getAuthToken } from '@/auth/utils'
import HttpRequest from './HttpRequest'

class AnnouncementProvider extends HttpRequest {
  findAll () {
    this.setHeader(getAuthToken())
    return this.get('/annoucement')
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/annoucement', payload)
  }

  update (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/annoucement/${id}`, payload)
  }

  remove (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/annoucement/${id}`)
  }
}

export default AnnouncementProvider
